<template>
	<div class="login-containner">
		<div class="login-box">
			<div class="avatar-box" style="display: none;">
				<img src="../assets/images/welcome.png" alt="">
			</div>
			<div class="login-title">智能情绪手表管理后台</div>
			<div class="tab-views">
				<div class="tab-view">
					<el-form ref="loginFormRef" label-width="0px" class="login-form">
						<el-form-item prop="username">
							<el-input v-model="account" placeholder="请输入账号" prefix-icon="el-icon-user-solid"></el-input>
						</el-form-item>
						<el-form-item prop="password">
							<el-input v-model="password" placeholder="请输入密码" show-password prefix-icon="el-icon-lock">
							</el-input>
						</el-form-item>
						<el-form-item class="btns">
							<el-button type="primary" @click="loginAccount">登录</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		login
	} from '../api/login'
	export default {
		data() {
			return {
				account: '',
				password: '',
			};
		},
		computed: {},
		methods: {
			loginAccount() {
				if (this.account == '') {
					return this.$message.error('请输入账号')
				}
				if (this.password == '') {
					return this.$message.error('请输入密码')
				}
				login(this.account, this.password).then(res => {
					if (res.status == 401) {
						return this.$message.error('密码错误')
					}
					if (res.status == 400) {
						return this.$message.error('无登录权限')
					}
					this.$message.success('登录成功')
					sessionStorage.setItem('token', res.data.token)
					sessionStorage.setItem('level', res.data.account.branch_level)
					sessionStorage.setItem('name', res.data.account.name)
					sessionStorage.setItem('account', res.data.account.account)
					sessionStorage.setItem('power', res.data.account.powers)
					sessionStorage.setItem('version', res.data.account.watch_version)
					console.log('power');
					console.log(res.data.account.powers);
					if (res.data.account.branch_level == 0) {
						this.$router.push('/team')
					} else {
						sessionStorage.setItem('admin-id', res.data.account.id)
						sessionStorage.setItem('company', res.data.account.company)
						sessionStorage.setItem('branch-content', res.data.account.branch_content)
						let arr = JSON.parse(res.data.account.powers);
						if (!arr.length) {
							return this.$message.info('无权限！');
						}
						let powerlist = [];
						arr.forEach(item=>{
							powerlist.push(item.path)
						})
						console.log("---------------");
						if(!powerlist.includes('/index')){
							console.log("------2---------");
							this.activePath = arr[0].path;
							this.$router.push(this.activePath)
						}else{
							console.log("------1---------");
							this.$router.push('/index')
						}
					}
				})
			}
		},
		created() {},
	};
</script>
<style lang='less' scoped>
	.login-containner {
		background-color: #2b4b6b;
		height: 100%;
	}

	.login-box {
		width: 450px;
		height: 350px;
		background-color: #fff;
		border-radius: 5px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		.avatar-box {
			height: 130px;
			width: 130px;
			border: 1px solid #eee;
			border-radius: 50%;
			padding: 10px;
			box-shadow: 0px 0px 10px #ddd;
			position: absolute;
			left: 50%;
			top: 0px;
			transform: translate(-50%, -50%);
			background-color: #fff;

			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				background-color: #eee;
			}
		}
	}

	.login-form {
		position: absolute;
		bottom: 0px;
		width: 100%;
		padding: 0 20px;
		box-sizing: border-box;
	}

	.login-title {
		height: 40px;
		line-height: 40px;
		font-size: 24px;
		font-weight: 600;
		color: #333;
		text-align: center;
		margin-top: 90px;
	}

	.btns {
		display: flex;
		justify-content: center;
	}
</style>
